@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Black.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-BlackItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-BoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-ExtraBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-ExtraLightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Italic-VariableFont_wght.ttf)
      format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-SemiBoldItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-ThinItalic.ttf) format("truetype");
}
@font-face {
  font-family: "PublicSans";
  src: local("PublicSans"),
    url(./assets/fonts/PublicSans-VariableFont_wght.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: PublicSans, Arial, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(247, 244, 244) !important;
}

/* sedhal added */
.ps-menu-label {
  font-size: 15px;
  text-align: left;
  color: #626c70;
}
.ps-menu-checkbox-label {
  font-size: 12px;
  text-align: left;
  color: #626c70;
}
.ps-menu-checkbox-label-1 {
  font-size: 12px;
  text-align: left;
  color: #626c70;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}
.ps-menu-checkbox-label-1:focus {
  box-shadow: none !important;
  border: none;
}
.ps-menu-checkbox-label:focus {
  box-shadow: none !important;
}
.ps-menu-button {
  height: 45px !important;
}
.sidebar {
  width: 16%;
  display: flex;
  height: 10vh;
  position: sticky;
  min-height: 40px;
  /* background-color: #ffffff; */
  float: left; /* or use display: inline-block; */
}
.collapsableSidebar {
  width: 5.8%;
  display: flex;
  height: 10vh;
  position: sticky;
  min-height: 40px;
  float: left;
  transition: width ease-out 0.25s;
  z-index: 11;
}
@media (width > 1090px) {
  .collapsableSidebar {
    width: 5.8%;
    display: flex;
    height: 10vh;
    position: sticky;
    min-height: 100vh;
    float: left;
    transition: width ease-out 0.25s;
    z-index: 11;
  }
}
.content {
  width: 84%;
  float: right;
}

.collapsableContent {
  width: 93%;
  float: right;
}
.main-content {
  /* height: calc(100vh - 60px); */
  /* overflow-y: auto; */
}
.sidebar .ps-menuitem-root.ps-active {
  background-color: #eaecee;
  border-right: 4px solid #4c7889;
}
.ps-menu-button:hover {
  background-color: #eaecee;
}
.ps-menu-button:hover svg path,
.ps-menu-button:hover .ps-menu-label,
.ps-menu-button.ps-active svg path,
.ps-menu-button.ps-active .ps-menu-label {
  stroke: #191b1c;
  color: #191b1c;
}
/* .ps-menu-button:hover .fuel-pump{
  stroke: #191B1C;
  color: #191B1C;
} */
.sidebar .ps-sidebar-root {
  width: 16%;
  min-width: 16%;
}
/* topbar */
.topbar {
  background-color: #ffffff;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 4;
}
.topbarCollapsed {
  background-color: #ffffff;
  height: 60px;
  position: sticky;
  top: 0;
  z-index: 4;
  width: 94.8%;
  margin-left: auto;
  transition: width ease-out 0.25s;
}
.introduction {
  text-align: left;
}
.dashboard {
  height: calc(100vh - 115px);
  text-align: left;
}
.content .dashboard {
  padding: 0px 20px;
}
.custom-card {
  border: 0 !important;
  border-radius: 6px !important;
}
.custom-card-setting {
  border: 0 !important;
  background-color: rgb(255, 255, 255) !important;
  color: rgba(0, 0, 0, 0.87) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important;
  width: 100% !important;
}
.font-bolder {
  font-weight: 700;
}
.rounded-circle {
  width: 20%;
  height: 20%;
  border-radius: 50%;
}
.list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.vehicle-no {
  font-size: 13px;
  font-weight: 700;
}
.today-km {
  color: rgb(58, 58, 58);
  font-size: 12px;
}
.vehicle-running {
  font-size: 12px;
  font-weight: 500;
  color: #0faf62;
}
.vehicle-details {
  text-align: left;
}
.doc-expired {
  font-size: 12px;
  color: red;
}
.font-size-12 {
  font-size: 12px !important;
}
.exportSVG {
  display: none !important;
}
.exportPNG {
  display: none !important;
}
.h-314 {
  height: 314px;
}
.plan-title {
  font-size: 20px;
  font-weight: 500;
}
.plan-desc {
  font-size: 16px;
  color: #626c70;
}
.plan-price {
  font-size: 26px;
  font-weight: 700;
}
.plan-year {
  font-size: 16px;
  color: #7b878c;
}
.plan-details {
  font-size: 14px;
  color: #4a5154;
}
.ml-2 {
  margin-left: 10px;
}
.plan-card:hover {
  background-color: #2a7c7a;
  color: #fff;
}
.plan-card:hover .plan-desc,
.plan-card:hover.plan-details,
.plan-card:hover .plan-year,
.plan-card:hover .plan-details {
  color: #fff;
}
.plan-card:hover .main-icon {
  path {
    stroke: #fff266;
  }
}

.plan-card:hover .plan-detail-text svg {
  path {
    fill: #fff266;
  }
}
.plan-card:hover .custom-btn {
  background-color: #fff266 !important;
  color: #191b1c !important;
  border: 0 !important;
}
.plan-card:hover .custom-btn svg path {
  stroke: #191b1c !important;
}
.custom-btn {
  width: 154px;
  color: #4c7889 !important;
  border-radius: 160px !important;
}
/* modal */
.add-vehicle-modal .modal-header .modal-title {
  font-size: 18px !important;
  font-weight: 500;
  color: #191b1c;
}
.btn-close {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #000 !important;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.close span {
  color: #fff;
}
.modal-content {
  border-radius: 8px;
}
.custom-form-label {
  font-size: 14px;
  font-weight: 600;
}
.plan-add-vehicle {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
.pay-now-btn {
  width: 166px;
  background: #2a7c76 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.form-control {
  font-size: 0.85rem !important;
  padding: 0.5rem 0.75rem !important;
  /* background: #E9E9E9 !important; */
}
.form-control:focus {
  box-shadow: none !important;
}
.field-error {
  color: red;
  font-size: 13px;
}
.text-left {
  text-align: left;
}
.ml-20 {
  margin-left: 20px;
}
/* faq page */
.faq-main-title {
  font-size: 30px;
  line-height: 36px;
}
.faq-desc {
  color: #7b878c;
  font-size: 14px;
}
.faq-details {
  color: #7b878c;
  font-size: 16px;
  line-height: 24px;
}
.faq-page-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
@media (max-width: 1365px) and (min-width: 1208px) {
  .faq-page-desc {
    height: 160px !important;
  }
}
@media (max-width: 1207px) and (min-width: 953px) {
  .faq-page-title {
    height: 80px !important;
    margin-bottom: 0px;
  }

  .faq-page-desc {
    height: 200px !important;
  }
}
@media (max-width: 952px) and (min-width: 760px) {
  .faq-page-title {
    height: 100px !important;
    margin-bottom: 0px;
  }

  .faq-page-desc {
    height: 250px !important;
  }
}
.faq-page-desc {
  font-size: 14px;
  font-weight: 400px;
  line-height: 24px;
  color: #626c70;
}
.faq-card {
  border: 0 !important;
  border-radius: 0 !important;
}
/* datatable */
.invoice-btn-text {
  font-size: 14px;
  font-weight: normal;
  color: #626c70;
  margin-left: 8px;
}
.action-dropdown {
  border-color: #e5e7e8 !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 24px 0px #191b1c29 !important;
}
.invoice-badge .bg-success {
  /* font-size: 12px; */
  /* margin-right: 5px; */
  margin-left: 3px;
}
.rdt_Pagination .fAiwtk,
.rdt_Pagination .epZUJV,
.rdt_Pagination .iOgnCO {
  color: #000000;
}
/* react datatable component  */
.rdt_TableHeadRow {
  text-transform: uppercase;
  border-top: 2px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12) !important;
  /* color: #4A5154 !important; */
}
.kuPCgf:not([disabled]) svg {
  fill: #2a7c76;
  border-radius: 50%;
}
.kuPCgf:hover:not(:disabled) {
  background-color: #2a7c76 !important;
}
.kuPCgf:hover:not(:disabled) svg {
  fill: #fff !important;
}
.lnOUep {
  color: #4a5154 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.rdt_Pagination {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.custom-title {
  text-align: left !important;
  color: #191b1c !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.pay-chalan-btn {
  border-radius: 160px !important;
  font-size: 11px !important;
  padding: 5px !important;
  width: 74px !important;
}
/* Settings page */
.settings {
  padding: 20px;
}
.custom-submit-btn {
  border: 0 !important;
  background: #2a7c76 !important;
  border-radius: 25px !important;
  font-size: 14px !important;
}
/* Settings */
.th-heading {
  background-color: #f8f9f9 !important;
  color: #4a5154 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  text-transform: uppercase;
}
.notifications tr td .title {
  font-weight: 700 !important;
  font-size: 13px !important;
}
.notifications tr td .details {
  font-weight: 400 !important;
  font-size: 13px !important;
}
.custom-switch {
  width: 3em !important;
  height: 1.5em !important;
}
.custom-switch {
  background-color: #efefef !important;
  border-color: #efefef !important;
}
.custom-switch:checked {
  background-color: #2a7c76 !important;
  border-color: #2a7c76 !important;
}
/* checkbox */
.custom-checkbox[type="checkbox"] {
  width: 1.5em;
  height: 1.1rem;
  accent-color: #2a7c76;
}
.p-checkbox {
  padding-top: 20px !important;
  padding-left: 20px !important;
}
.notification-card {
  box-shadow: 0px 4px 4px 0px #00000040 !important;
}
.notificaton-title {
  font-size: 15px;
  font-weight: 600;
}
.n-title {
  font-size: 14px;
  font-weight: 400;
}
.custom-lable {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #626c70 !important;
}
.css-hezfmt-control {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.375rem !important;
  transition: #dee2e6 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
.custom-radio .form-check-input:checked {
  background-color: #2a7c76;
  border-color: #2a7c76;
}
.h-320 {
  height: 320px !important;
}
/* place holder css */
.css-1jqq78o-placeholder {
  font-size: 13px;
}
input::-webkit-input-placeholder {
  font-size: 13px;
  line-height: 4;
}
/* login page */
.login-parent {
  height: 100%;
}
.login-card {
  border: 0 !important;
  border-radius: 20px !important;
  background-color: #2a7c76 !important;
}
.custom-login-btn {
  width: 130px;
  background-color: #ffc511 !important;
  font-size: 14px !important;
  border: 0 !important;
}
.custom-login-otp-btn {
  border-color: #fff !important;
  color: #fff !important;
  font-size: 14px !important;
}
.login-input-lable {
  font-size: 14px;
  font-weight: normal;
}
.welcome-text {
  color: #333c45;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
}
.font-700 {
  font-size: 45px;
  font-weight: 700;
}
.login-left-content {
  margin-top: 8% !important;
  text-align: left;
  margin-left: 4%;
}
.login-right-content {
  margin-top: 8% !important;
  text-align: left;
  /* margin-left: 4%; */
}
.custom-login-otp-btn:hover {
  border-color: #ffc511 !important;
  background-color: #ffc511 !important;
}
.resend-otp {
  color: #ffc511;
}
.disabled {
  pointer-events: none;
}
.custom-datatable {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  width: 100%;
  margin-bottom: 16px;
}
.breadcrumb-parent {
  padding: 20px 20px 0px 20px;
}
.breadcrumb-parent .breadcrumb {
  margin: 0;
}
.breadcrumb {
  display: flex;
  align-items: center;
}
.breadcrumb-title {
  font-weight: 600;
  font-size: 18px;
  color: #191b1c;
  text-transform: capitalize;
}
.breadcrumb-item.active {
  color: #626c70;
  font-size: 14px;
}
.breadcrumb-item a {
  color: rgb(33, 43, 54);
  font-size: 14px;
  font-weight: normal;
}
.vehicle-info p {
  color: #000000;
}
.vehicleStatusText {
  color: #fff !important;
  text-transform: uppercase;
}
.hw-100 {
  height: 100vh;
}
.emergency-services {
  padding: 8px;
  background-color: #2a7c76;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.emergency-services .title {
  font-size: 12px;
  margin-top: 5px;
}
/* fuel progress */
.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
}

.progress.vertical {
  position: relative;
  width: 75px;
  height: 230px;
  display: inline-block;
  margin-right: 10px;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute !important;
  bottom: 0;
}

.progress-bar {
  background-color: #2a7c76 !important;
  box-shadow: none;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #2a7c76 !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
.progress-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 235px;
}
.progress-label .label-item {
  color: #000000;
  font-size: 14px;
}
.text-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.fuel-info .title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
}
.fuel-info .value {
  font-size: 14px;
  color: #585858;
}
.ml-5 {
  margin-left: 10px;
}
.fuel-title {
  text-align: left;
  font-size: 12px;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.ml-15 {
  margin-left: 15px;
}
.fuel-station-scroll {
  overflow-y: auto;
  height: 250px;
  min-height: 250px;
}
/* scrollbar */
::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}
::-webkit-scrollbar-button {
  background: #ccc;
}
::-webkit-scrollbar-track-piece {
  background: #2a7c76;
}
::-webkit-scrollbar-thumb {
  background: #eee;
}
/* drawer css */
.side-drawer {
  /* In commentaries are the way to make it slide from left to right */
  position: fixed;
  height: 100%;
  background: #fff;
  top: 0;
  right: 0;
  /* right: 100%; */
  width: 30%;
  z-index: 200;
  box-shadow: 1px 0px 7px fff(0, 0, 0, 0.5);
  transform: translateX(100%);
  /* transform: translateX(0%); */
  transition: transform 0.3s ease-out;
}
.side-drawer.open {
  transform: translateX(0);
  /* transform: translateX(100%); */
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
}
.primary-btn:hover {
  background-color: #2a7c76 !important;
  border-color: #2a7c76 !important;
  color: #fff !important;
}
.primary-btn.active {
  background-color: #2a7c76 !important;
  border-color: #2a7c76 !important;
  color: #fff !important;
}
.primary-btn-geofence {
  background-color: #2a7c76 !important;
  border-color: #2a7c76 !important;
  color: #fff !important;
}
.primary-btn {
  color: #2a7c76 !important;
  border-color: #2a7c76 !important;
  font-size: 14px !important;
}
.history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -7px;
  margin-left: 8px;
}
.history .stopped {
  color: #e2473f;
  font-size: 16px;
  font-weight: 500;
}
.history .running {
  color: #2a7c76;
  font-size: 16px;
  font-weight: 500;
}
.history .total-time {
  font-size: 12px;
  color: #585858;
  margin-left: 10px;
}
.history .address {
  font-size: 14px;
  color: #585858;
  text-align: left;
}
.history .date-time {
  font-size: 14px;
  color: #585858;
}
.mtb-10 {
  margin-top: 20px;
}
.history-horizontal-scroll {
  height: 100%;
  overflow-y: scroll;
  max-height: 550px;
}
.fuel-station-parent {
  padding: 0px 20px 0px 20px;
}
.route-date-picker .react-datepicker-popper {
  /* display: none; */
  right: 30px !important;
  left: 0px !important;
}
.route-date-picker .react-datepicker__input-container {
  display: none;
}
.plrb-4 {
  padding: 0em 1.5rem 1.5rem 1.5rem;
}
.h-240 {
  height: 240px;
}
.view-all {
  font-size: 14px;
}
/* .huezRP {
  padding-bottom: 20px;
} */

.image-container {
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.google-map-parent {
  position: relative;
}
.map-custom-icons {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 15px;
}
.map-custom-icons-dashboard {
  position: absolute;
  top: 88px;
  right: 7px;
  /* margin-right: 15px; */
}
.map-custom-icons-fuelfill {
  position: absolute;
  top: 5px;
  right: 5px;
  margin-right: 15px;
}
.image-container-active {
  width: 40px;
  height: 40px;
  overflow: hidden;
  background-color: #2a7c76;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.image-container-active svg path {
  fill: #fff;
}
.ml-10 {
  margin-left: 10px;
}
/* .google-map-route-watch .gm-style-iw-chr {
  position: absolute;
  right: 0;
} */
.google-map-parent .gm-style-iw-d {
  overflow: auto !important;
}
.infowindow-play-route {
  margin-top: 10px;
}
.google-map-parent .gm-style-iw {
  background: rgba(0, 0, 0, 0.68) !important;
}
.google-map-parent .gm-style-iw-tc::after {
  background: rgba(0, 0, 0, 0.68) !important;
}
.google-map-parent .gm-ui-hover-effect {
  position: absolute !important;
  top: 0;
  right: 0;
}
.google-map-parent .gm-ui-hover-effect > span {
  background-color: #fff !important;
}
.live p {
  color: #fff;
}
.stops p {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #dad8d8 !important;
}
.mr-10 {
  margin-right: 10px;
}
.btn-route-active-btn {
  color: #fff !important;
  border-color: #2a7c76 !important;
  font-size: 14px !important;
  background-color: #2a7c76 !important;
}
.font-600 {
  font-size: 13px;
  font-weight: 600;
}
.react-toast-title {
  font-weight: 700;
  font-size: 15px;
}
.react-toast-body {
  font-size: 13px;
}
.run-time-box {
  width: 45%;
  background: #ebf7f7;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
}
.stop-time-box {
  width: 45%;
  background: #ffeded;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: left;
  cursor: pointer;
}
.run-time-box .title {
  font-size: 14px;
  font-weight: 600;
  color: #2a7c76;
}
.run-time-box .value,
.stop-time-box .value {
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}
.stop-time-box .title {
  font-size: 14px;
  font-weight: 600;
  color: #e2473f;
}
.run-time-box-active {
  border: 2px solid #2a7c76;
}
.stop-time-box-active {
  border: 2px solid #e2473f;
}
.logo-parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  color: #212529;
  padding: 5px 25px;
  height: 60px;
  position: fixed;
}
.logo-parent svg {
  width: 20px;
  height: 20px;
}
.logo {
  margin-left: 10px;
  font-size: 24px;
  color: #fff;
  transition: all 0.3s ease;
  margin-bottom: 0;
}

.logo-collapsed {
  display: none;
  font-size: 18px;
  margin-left: 10px;
}
.support-page-form {
  align-items: flex-end;
}
.vehicle-list-parent {
  height: 100%;
  max-height: 530px;
  overflow-y: auto;
  padding: 0;
}
.vehicle-list-parent-fuel {
  height: 100%;
  max-height: 460px;
  overflow-y: auto;
  padding: 0;
}
/* Mobile responsive media query */
/* Media query for mobile devices (up to 575.98px) */
@media (max-width: 575.98px) {
  .content {
    width: 100%;
    position: absolute;
  }
  .sidebar {
    z-index: 9;
  }
  .topbar {
    width: 80%;
    margin-left: 20%;
    z-index: 11;
  }
  .custom-login-btn {
    width: 100px;
  }
  .logo {
    display: none;
  }
  .burger-menu {
    pointer-events: none;
    cursor: default;
  }
  #popover-contained {
    width: 70% !important;
  }
  #popover-contained-notification {
    width: 100% !important;
  }
  .dashboard-desc {
    font-size: 14px;
  }
  .vehicle-tabs {
    text-align: center; /* Center the list items */
  }

  .vehicle-tabs li {
    display: block; /* Display list items as block elements */
    margin: 5px 0; /* Add margin for spacing between list items */
  }
  .faq-main-title {
    font-size: 24px;
    line-height: 32px;
  }
  .faq-page-title {
    font-size: 17px;
  }
  .faq-page-desc {
    font-size: 13px;
  }
  .collapsableSidebar {
    width: 20% !important;
  }
  .fuel-info .title {
    font-size: 12px;
  }
  .fuel-info .value {
    font-size: 12px;
  }
  .emergency-services {
    padding: 6px;
  }
   {
    top: 75px;
  }
  .side-drawer.open {
    width: 90%;
    transform: translateX(0);
    /* transform: translateX(100%); */
  }
  .search-component {
    flex-direction: column;
  }
  .Geofence-main-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-container {
    max-width: 100%;
    width: 100%;
    height: 600px;
  }
  .map-container {
    width: 100%;
    padding: 1rem !important;
  }
  .vehicle-list-parent {
    height: 100%;
    max-height: 430px;
    overflow-y: auto;
    padding: 0;
  }
}
/* @media (max-width : 745px) {
  .search-component{
    flex-direction: column;
   }
} */
@media (min-width: 575.98px) and (max-width: 850px) {
  .Geofence-main-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-container {
    max-width: 100%;
    width: 100%;
    height: 600px;
  }
  .map-container {
    width: 100%;
    padding: 1rem !important;
  }
}
@media (min-width: 575.98px) and (max-width: 1090px) {
  .content {
    width: 100%;
    position: absolute;
  }
  .sidebar {
    z-index: 9;
    width: 30%;
  }
  .collapsableSidebar {
    width: 10%;
  }
  .topbar {
    width: 90%;
    margin-left: 10%;
    z-index: 11;
  }
  .sidebar .ps-sidebar-root {
    width: 30%;
    min-width: 30%;
  }
  .side-drawer {
    width: 60%;
  }
  .vehicle-list-parent {
    height: 100%;
    max-height: 730px;
    overflow-y: auto;
    padding: 0;
  }
  .vehicle-list-parent-fuel {
    height: 100%;
    max-height: 490px;
    overflow-y: auto;
    padding: 0;
  }
}
@media only screen and (min-width: 321px) and (max-width: 768px) {
  .support-page-form {
    align-items: flex-start;
  }
}
/* .losPks {
  min-height: 200px;
} */
.max-text {
  font-size: 10px;
}
.w-145 {
  width: 145px !important;
}
.breadcrumb .breadcrumb-item {
  text-transform: uppercase;
}
.spinner-primary {
  color: rgba(42, 124, 118, 1) !important;
}
/* media query */
@media screen and (min-width: 1024px) and (max-width: 1310px) {
  .side-drawer {
    width: 40%;
  }
}
@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 5px rgba(25, 135, 85, 0.5);
  }
  50% {
    box-shadow: 0 0 15px rgba(25, 135, 85, 0.7);
  }
  100% {
    box-shadow: 0 0 5px rgba(25, 135, 85, 0.5);
  }
}
.selected-vehicle {
  border: 2px solid rgb(25 135 85) !important;
}
.selected-vehicle-fuelAnalytics {
  border: 2px solid rgb(25 135 85) !important;
  animation: shadowPulse 2s infinite;
}
.report-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 25px;
}
.report-desc {
  font-size: 13px;
  color: #626c70;
  margin-bottom: 30px;
}
.search-component {
  display: flex;
}
.report-table thead tr th {
  text-transform: capitalize;
  font-size: 14px;
}
.report-table tbody tr td {
  font-size: 12px;
}
.report-address {
  text-align: left;
  width: 30rem;
}
.vehicle-filter {
  cursor: pointer;
}
.h-340 {
  height: 340px;
}
.h-350 {
  height: 348px;
}
.main-div-contact {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: space-around;
}

.child-div-contact {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.support-icon {
  margin-bottom: 4px;
}
.support-name {
  font-size: 15px;
  /* margin-top: 4px; */
}
.support-number {
  font-size: 0.8em;
}
/* .support-box{
  height: 102px;
} */
@media (770px <= width <= 990px) {
  .main-div-contact {
    flex-direction: column;
  }
  .support-name {
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .main-div-contact {
    flex-direction: column;
  }
  .child-div-contact {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .support-icon {
    padding: 4px;
  }
  .support-name {
    padding: 4px;
  }
}
.custom-lable-support {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #626c70 !important;
}
.applyBtn {
  background: #2a7c76 !important;
  border-color: #2a7c76 !important;
}
.daterangepicker td.active {
  background-color: #2a7c76 !important;
}
.swal2-popup.swal2-toast .swal2-title {
  font-size: 0.9em !important;
  font-weight: normal !important;
}
.custom-info-toll-window {
  text-align: left;
  padding-right: 12px;
  padding-bottom: 12px;
}
.custom-info-toll-window h6 {
  color: #fff;
}
.custom-info-toll-window span {
  color: #fff;
  margin-bottom: 2px !important;
}
.custom-route-btn {
  margin-right: 10px;
}
.root-title {
  margin-bottom: 0.3rem;
  color: #000000;
  font-size: 0.8rem;
  font-weight: 700;
}
.root-value {
  margin-bottom: 0.3rem;
  color: rgba(101, 101, 117, 1);
  font-size: 0.8rem;
}
.btn-geo {
  border: none;
  border-radius: 10px;
  background: none;
  /* margin: 5px; */
  /* background-color: #0FAF62; */
  /* padding: 5px; */
  padding-right: 10px;
  padding-left: 10px;
}
.btn-geo-create {
  border: none;
  border-radius: 7px;
  background: none;
  margin: 5px;
  background-color: #2a7c7a;
  padding: 5px;
  padding-right: 7px;
  padding-left: 7px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-geo-secondry {
  border: none;
  border-radius: 7px;
  background: none;
  margin: 5px;
  background-color: #97aca2;
  padding: 5px;
  padding-right: 7px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-geo:hover {
  color: #84a7f1;
  cursor: pointer;
}

.Geofence-main-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.form-container {
  flex: 1;
  min-width: 300px;
}

.form-group {
  margin-bottom: 1rem;
  padding-left: 16px;
  margin-bottom: 16px;
  text-align: left;
}

.custom-label {
  font-weight: bold;
}

/* .form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
} */

.radio-group {
  margin-bottom: 1rem;
}

.radio-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.radio-label input {
  margin-right: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.map-container {
  flex: 2;
  min-width: 300px;
  height: 400px; /* Adjust height as needed */
  border-radius: 20px;
}

.vehicleDetailValue.unavailable {
  color: gray;
  font-style: italic;
}

.grayed-out {
  opacity: 0.5;
  background-color: #f0f0f0;
  pointer-events: none;
}

.vehicleDetailValue {
  color: #333;
}

.map-item {
  cursor: pointer;
  transition: text-decoration 0.3s ease; /* Smooth transition */
}

.map-item:hover {
  text-decoration: underline;
}

/*  */
/* ResponsiveTable.css */

/* .responsive-table-container {
  overflow-x: auto;
  margin: 20px;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th, .responsive-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.responsive-table th {
  background-color: #f4f4f4;
}

.pagination {
  margin-top: 10px;
  text-align: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  background-color: #f4f4f4;
} */
/* ResponsiveTable.css */

.responsive-table-container {
  margin: 20px;
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.responsive-table th,
.responsive-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.responsive-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.responsive-table td {
  position: relative;
}
.submit-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.action-access-button {
  /* padding: 0.5em 0.5em; */
  background-color: #96a5b4;
  border: none;
  color: #131212;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  margin-right: 1em;
}
.action-button {
  /* padding: 0.5em 0.5em; */
  background-color: #96a5b4;
  border: none;
  color: #131212;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  margin-right: 1em;
}
.action-update-button {
  /* padding: 0.5em 0.5em; */
  background-color: #96a5b4;
  border: none;
  color: #131212;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  /* margin-right: 0.5em; */
}

.action-button:hover {
  background-color: #e60000;
  color: white;
}
.action-update-button:hover {
  background-color: #319642;
  color: white;
}
.action-access-button:hover {
  background-color: #0087bd;
  color: white;
}

.pagination {
  margin-top: 10px;
  text-align: center;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.pagination-info {
  margin: 0 10px;
  font-size: 14px;
}

/*  */
/* User.css */

.user-container {
  margin: 20px;
}

.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-header input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 300px;
}

.add-user-button {
  background-color: #198754;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.add-user-button:hover {
  background-color: #0b3f27;
}

.add-user-button svg {
  margin-right: 8px;
}

.add-user-button:disabled {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.speed-button {
  background-color: white;
  color: black;
  border: solid 1.5px #198754;
  border-radius: 5px;
  padding: 2px 6px;
  font-size: 12px;
  cursor: pointer;
  /* margin-left: 50px; */
  transition: background-color 0.3s, transform 0.2s;
}

.burger-button {
  background: none;
  border: none;
}
.dropdown-analytics {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
.dropdown-analytics-child {
  width: 180px;
  z-index: 1;
}
.dropdown-analytics-child {
  width: 180px;
  z-index: 1;
}
.fuel-pump svg path {
  stroke: #626c70 !important;
  color: #626c70 !important;
}
.fuel-pump:hover svg path {
  stroke: #191b1c !important;
  color: #191b1c !important;
}

.sidebar .ps-menuitem-root .ps-active .fuel-pump svg path {
  stroke: #191b1c !important;
  color: #191b1c !important;
}

@media (width < 1089px) {
  .no-padding-row {
    padding-right: 0;
    padding-left: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }
}

.reportsWrapper {
  margin: 20px;
}
.report-link {
  text-transform: uppercase;
  font-size: 14px;
}
/* sedhal added */
.slider-main {
  position: absolute;
  width: 100%;
  bottom: 26px;
}
.slider-parent {
  border-radius: 5px;
  background: rgb(214, 225, 230);
  border: 1px solid rgb(214, 225, 230);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 50px 0px 12px;
  padding: 10px;
}

.horizontal-slider {
  width: 100%;
  max-width: 100%;
  padding: 13px 0px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  border: 5px solid gray;
  box-sizing: border-box;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: rgb(28, 133, 232);
}

.example-track.example-track-1 {
  background: #a7cef1;
}

.example-track.example-track-2 {
  background: #a7cef1;
}

.horizontal-slider .example-track {
  height: 5px;
}

.horizontal-slider .example-thumb {
  top: 1px;
  width: 50px;
  height: 48px;
  line-height: 38px;
}
.thumb-image {
  cursor: pointer;
  margin-top: -21px;
}
.speed-dropdown {
  min-width: 32px !important;
  border: 0 !important;
}
.dropdown-toggle {
  background-color: rgb(28, 133, 232) !important;
  border: 0 !important;
  padding: 7px 15px !important;
  border-radius: 20px !important;
}
.map-view-icon {
  position: absolute;
  right: 25px;
  top: 10px;
}
.dark-tooltip {
  background-color: #333; /* Dark background */
  color: #fff; /* Light text color */
  border-radius: 4px; /* Rounded corners */
  padding: 8px; /* Padding inside the tooltip */
  font-size: 14px; /* Font size */
}

.dark-tooltip::after {
  border-top-color: #333; /* Triangle color */
}
.w-230 {
  width: 230px;
}
.ml-3 {
  margin-left: 15px !important;
}
.font-14 {
  font-size: 14px;
}
.w-200 {
  max-width: 200px;
}
.daterangepicker .ranges li.active {
  background-color: #2a7c76 !important;
}
.dark-tooltip {
  font-size: 12px !important;
}
.css-jn69v9 {
  width: 10px;
  height: 10px;
}

/* Targeting the Google Maps zoom control buttons inside .gmnoprint */
.gmnoprint button.gm-control-active {
  background-color: #ffffff; /* Change background color */
  border: 2px solid #2a7c7a; /* Add a border around the buttons */
  width: 60px; /* Set custom width */
  height: 60px; /* Set custom height */
  border-radius: 8px; /* Make buttons rounded */
  color: #444; /* Set custom text color */
  cursor: pointer; /* Show pointer cursor */
  font-size: 20px; /* Adjust font size */
  margin: 10px; /* Set space around buttons */
}

/* Change button style on hover */
.gmnoprint button.gm-control-active:hover {
  background-color: #f1f1f1; /* Change background color on hover */
  border: 2px solid #2a7c7a; /* Border color on hover */
  color: #2a7c7a; /* Change text color on hover */
}

/* Optional: Style the button container */
.gmnoprint.gm-bundled-control {
  margin-bottom: 20px; /* Adjust spacing of the control container */
  right: 30px; /* Position adjustment */
}

.ps-submenu-expand-icon .css-honxw6 {
  width: 7px;
  height: 7px;
}

.ps-submenu-expand-icon .css-jn69v9 {
  width: 7px;
  height: 7px;
}

.karan .fNLvxm {
  display: flex;
}
.karan .igTRoD {
  display: flex;
}

.dropdown-heading .clear-selected-button .dropdown-search-clear-icon line {
  width: 20px;
  height: 20px;
}
.scrollable-card-body {
  max-height: 400px;
  overflow-y: scroll;
  position: relative;
}
.report-results .card-header {
  position: sticky;
  top: -1px;
  /* z-index: 3; */
  background-color: #fff !important;
}
.countdown {
  color: rgb(28, 133, 232);
  padding-left: 10px;
  padding-right: 5px;
  font-size: 14px;
}

/* Sticky Table Header */
.sticky-header thead th {
  position: sticky;
  top: 0;
  /* z-index: 3; */
}

/* .datatableResponsive .fNLvxm {
  display: flex;
} */
.datatableResponsive .huezRP {
  overflow-x: auto;
}
.datatableResponsive .igTRoD {
  display: flex;
}
.datatableResponsive .losPks {
  overflow-x: auto;
  padding-bottom: 0px;
}

/* .css-b62m3t-container {
  z-index: 11;
} */
.eaIfSD {
  width: 100px;
}
@media (width < 700px) {
  .eaIfSD {
    width: auto;
  }
}
.daTZoB {
  width: 100px;
}
@media (width < 700px) {
  .daTZoB {
    width: auto;
  }
}

.sc-blmEgr {
  text-align: left;
}
.ps-menu-checkbox-label input[type="checkbox"] {
  accent-color: #2a7c76 !important;
}
.ps-menu-checkbox-label input[type="checkbox"] {
  width: 14px !important;
  height: 14px !important;
}
.alert-select-date {
  font-size: 12px !important;
}
.rmsc .item-renderer {
  align-items: center !important;
}
.speed-toggle {
  padding: 3px 10px !important;
  font-size: 14px !important;
}
.dropdown-toggle-parent {
  margin-right: 5px;
}
.dropdown-toggle-parent .dropdown-item {
  font-size: 13px;
}

.dropdown-menu {
  margin-top: -80px !important;
  margin-left: -20px !important;
}
/* sedhal added */
.dashboard-map .gm-fullscreen-control {
  border-radius: 20px !important;
}
.tyre-btn-size {
  font-size: 14px !important;
}
.invalid-feedback {
  font-size: .76em !important;
}
.w-85 {
  width: 85%;
}
.installing-date.is-invalid {
  background-image: none !important;
}
.installing-date:valid {
  background-image: none !important;
}